<template>
  <div>
                    <div><v-divider class="primary--text"></v-divider></div>
                    <div class="pt-2 text-title-3 primary--text text--darken-4">{{title}}</div>
                   </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.v-divider {
    display: block;
    flex: 1 1 0px;
    max-width: 100%;
    height: 4px;
    max-height: 4px;
    border: solid;
    border-width: 1 0 0 0;
    transition: inherit;
    border-radius: 4px
}
.theme--light.v-divider {
    border-color: #245DDB
}
</style>
