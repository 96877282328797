<template>
<v-card tile color="primary darken-4">
    <v-card-title><v-spacer/><v-btn icon @click="$emit('onClose')"><v-icon color="natural lighten-4">mdi-close</v-icon></v-btn></v-card-title>
    <v-card-text class="text-center">
   <device v-bind="$data">
    <v-app-bar
      color="primary"
      dense
      dark
    >
      <v-spacer></v-spacer>
      <v-toolbar-title>{{form.name}}</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
     <v-row class="pt-7">
        <v-col cols="4"><StepCardInActive :title="'ข้อมูลส่วนตัว'" /></v-col>
        <v-col cols="4"><StepCardActive :title="'ข้อมูลสุขภาพ'" /></v-col>
        <v-col cols="4"><StepCardInActive :title="'สำเร็จ'" /></v-col>
      </v-row>
  <v-card  tile height="550px">
    <v-card-text class="text-left">
       <v-form ref="form" v-model="valid" lazy-validation>
                <v-row dense>
                  <v-col
                    v-for="(item, index) in form.schema.fields"
                    :key="index"
                    cols="12"
                  >
                    <SelectMultiWidget
                     v-if="item.type === 'select' && visibleCondtion(item) && item.multiple && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />
                    <!--.schema.fields -->
                    <SelectWidget
                      v-if="item.type === 'select' && visibleCondtion(item) && !item.multiple && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />
                    <!-- <NumberWidget v-if="item.type==='number'" :question="item"/> -->
                    <TextWidget
                      class="pa-0 ma-0"
                      v-if="item.type === 'text' && visibleCondtion(item) && item.visible"
                      v-bind="item"
                      v-model="item.value"
                    />
                  </v-col>
                </v-row>
              </v-form>
    </v-card-text>
  </v-card>
   </device>
    </v-card-text>
</v-card>
</template>

<script>
import device from 'vue-and-devices-mocks'
import StepCardActive from '@/components/preview/StepCardActive'
import StepCardInActive from '@/components/preview/StepCardInactive'
// import TextField from '@/components/preview/TextField'
// import TextArea from '@/components/preview/TextArea'
import SelectWidget from '@/components/preview/SelectWidget'
import SelectMultiWidget from '@/components/preview/SelectMultiWidget'
import TextWidget from '@/components/preview/TextWidget'
import { mapFields } from 'vuex-map-fields'

const required = (msg) => (v) => !!v || msg

export default {
  components: {
    device,
    StepCardActive,
    StepCardInActive,
    SelectWidget,
    SelectMultiWidget,
    TextWidget
  },
  created () {
    this.buildSchema()
  },
  computed: {
    ...mapFields(['form'])
  },
  methods: {
    buildSchema () {
      this.form.schema.fields.map((obj) => {
        if (obj.required) {
          obj.rules = [required('กรุณากรอกข้อมูล')]
        }
        if (obj.visibleIf) {
          const property = obj.visibleIf.match(/\{(.*?)\}/)
          const val = obj.visibleIf.match(/'([^']+)'/)
          obj.conditionprop = property[1].trim()
          obj.conditionval = val[1].trim()
        }
      })
    },
    visibleCondtion (item) {
      if (!item.visibleIf) return true
      const filterObj = this.form.schema.fields.filter(
        (obj) => obj.name === item.conditionprop
      )
      // console.log('filter condition' + filterObj.length)
      if (!filterObj.length) return true
      // if (filterObj[0].value) console.log('Check condition' + filterObj[0].value + '=' + item.conditionval)
      if (filterObj[0].value && (filterObj[0].value === item.conditionval)) {
        return true
      } else {
        return false
      }
    }
  },
  data () {
    return {
      type: 'iphone',
      scale: 25,
      width: null,
      height: null,
      skins: ['black', 'noShadow'],
      valid: false,
      form2: {
        schema: {
          fields: [
            {
              title: 'เพศ',
              name: 'gender',
              required: true,
              choices: ['ชาย', 'หญิง'],
              type: 'select'
            },
            {
              title: 'น้ำหนัก',
              name: 'weight',
              placeholder: 'กิโลกรัม',
              required: true,
              type: 'text',
              inputType: 'number',
              value: 90
            },
            {
              title: 'ความดันโลหิตตัวบน',
              name: 'sys',
              placeholder: 'mmHg',
              required: true,
              type: 'text',
              inputType: 'number'
            },
            {
              title: 'ความดันโลหิตตัวล่าง',
              name: 'dia',
              placeholder: 'mmHg',
              required: true,
              type: 'text',
              inputType: 'number'
            },
            {
              title: 'อัตราการเต้นหัวใจ',
              name: 'pulse',
              placeholder: 'bpm',
              required: true,
              type: 'text',
              inputType: 'number'
            },
            {
              title: 'วิธีตรวจน้ำตาล',
              name: 'dtx',
              required: true,
              choices: ['DTX', 'FBS'],
              type: 'select'
            },
            {
              title: 'การตรวจวัดน้ำตาล',
              name: 'method',
              required: true,
              choices: ['โดยอดอาหาร', 'โดยไม่อดอาหาร'],
              type: 'select'
            },
            {
              title: 'ผลตรวจวัดน้ำตาล (mg/dl)',
              name: 'bg',
              placeholder: 'mg/dl',
              required: true,
              type: 'text',
              inputType: 'number'
            },
            {
              title: 'ประวัติการสูบบุหรี่',
              name: 'smoke',
              required: true,
              choices: [
                'ไม่สูบบุหรี่',
                'นานๆครั้ง',
                'เป็นครั้งคราว',
                'สูบเป็นประจำ'
              ],
              type: 'select'
            },
            {
              title: 'ประวัติการดื่มแอลกอฮอล',
              name: 'alh',
              required: true,
              choices: ['ไม่ดื่ม', 'นานๆครั้ง', 'เป็นครั้งคราว', 'ดื่มเป็นประจำ'],
              type: 'select'
            },
            {
              title: 'ญาติสายตรงของท่านมีประวัติการเจ็บป่วย',
              name: 'familyDesease',
              required: true,
              choices: ['ไม่มีโรค', 'มีโรค', 'ไม่ทราบ'],
              type: 'select',
              value: 'ไม่มีโรค'
            },
            {
              title: 'โรคประจำตัวของญาติท่าน',
              name: 'familyNCD',
              required: true,
              visibleIf: "{familyDesease}='มีโรค'",
              choices: ['เบาหวาน', 'ความดัน'],
              type: 'select'
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .mobilePreview{
    display:block;
    width:100%;
    height:200px;
    background-color:#475;
    overflow:scroll;
    }
    .v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>
